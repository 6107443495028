import { ClassData } from '@models/class-data';

export class EventNewsItem {
  id: number;
  createdAt?: Date;
  eventTemplateId: number;
  title?: string;
  subtitle?: string;
  content?: string;
  order?: number;

  constructor(json: ClassData<EventNewsItem>) {
    this.id = +json.id;
    this.eventTemplateId = +json.eventTemplateId;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }
    this.title = json.title;
    this.subtitle = json.subtitle;
    this.content = json.content;
    this.order = json.order;
  }
}
