import { ClassData } from '@models/class-data';
import { EventTemplateLayout } from '@models/design-templates/event-template-layout.enum';
import { EventTemplateWidget } from '@models/design-templates/event-template-widget.model';
import { EventAttendeeQuestion } from '@models/event-attendance/event-attendee-question.model';
import { EventCategory } from '@models/event-categories/event-category.model';
import { EventAsset } from '@models/events/event-asset.model';
import { EventRSVPSettings } from '@models/events/event-rsvp-settings.model';
import { EventSectionType } from '@models/events/event-section-type.enum';
import { EventSponsor } from '@models/events/event-sponsor.model';
import { EventTestimonial } from '@models/events/event-testimonial.model';
import { EventWishlistItem } from '@models/events/event-wishlist-item.model';
import { ImagePosition } from '@models/events/image-position.enum';
import { User } from '@models/users/user.model';
import { EventTemplateAccess } from './event-template-access.enum';
import { EventTemplateRelevance } from './event-template-relevance.enum';
import { Address } from '@models/shared/address.model';
import { EventTemplateVisual } from './event-template-visual.model';
import { EventTemplateFont } from './event-template-font.model';
import { EventWidget } from '@models/events/event-widget.enum';
import { EventTemplateInvitationCard } from './event-template-invitation-card.model';
import { Kit } from '@models/pricing-plans/kit.enum';
import { EventTemplateAffiliateProduct } from './event-template-affiliate-product.model';
import { EventTemplateSeo } from './event-template-seo.model';
import { Keyword } from '@models/shared/keyword.model';
import { EventAffiliateProductType } from '@models/events/event-affiliate-product-type.enum';
import { AffiliateProduct } from '@models/affiliate-products/affiliate-product.model';
import { EventTemplateCreationRequest } from './dto/event-template-creation.request';
import { createDataUrl } from '@models/shared/asset.model';
import { environment as env } from '@environments/environment';
import { EventCreationRequest } from '@models/events/dto/event-creation.request';
import { EventType } from '@models/events/event-type.enum';
import { EventSection } from '@models/events/event-section.model';
import { EventTimetable } from '@models/events/event-timetable.model';
import { EventWeddingParty } from '@models/events/event-wedding-party.model';
import { EventFaq } from '@models/events/event-faq.model';
import { EventTemplateMarketplaceVisual } from './event-template-marketplace-visual.model';
import { EventTemplateTranslation } from '@models/design-templates/event-template-translation.model';
import { LegalInfo } from '@models/legal-info/legal-info.model';
import { EventPictureInfo } from '@models/events/event-picture-info.model';
import { EventNewsItem } from '@models/events/event-news-item.model';
import { LegalInfoLink } from '@models/legal-info/legal-info-link.model';

export class EventTemplate {
  id: number;
  createdAt?: Date;

  type: EventType;

  originalId?: number;

  creatorId: number;
  creator?: User;

  uri?: string;

  name: string;

  // event info
  description: string;
  eventName: string;
  eventCta: string;

  access: EventTemplateAccess;
  relevance?: EventTemplateRelevance;

  shownOnSite: boolean;

  eventCategories?: EventCategory[];
  eventSubcategories?: EventCategory[];

  startDate?: Date;
  endDate?: Date;
  timeZone?: string;

  promotionStartDate?: Date;
  promotionEndDate?: Date;
  promotionTimeZone?: string;

  targetGroups?: number[];

  visualId: number;
  visual?: EventTemplateVisual;

  fontId: number;
  font?: EventTemplateFont;

  widgets?: EventWidget[];
  widgetsFull?: EventTemplateWidget[];
  kits?: Kit[];

  partnerId?: number;
  partner?: User;

  affiliateProducts?: EventTemplateAffiliateProduct[];

  eventAddressId: number;
  eventAddress?: Address;

  standardInvitationText: string;

  invitationCardId: number;
  invitationCard?: EventTemplateInvitationCard;

  seoId: number;
  seo?: EventTemplateSeo;

  rsvpSettings?: EventRSVPSettings;
  attendeeQuestions?: EventAttendeeQuestion[];
  eventSections?: EventSection[];

  eventTimetableId?: number;
  eventTimetable?: EventTimetable;

  eventWeddingPartyId?: number;
  eventWeddingParty?: EventWeddingParty;

  eventFaqs?: EventFaq[];

  keywords?: Keyword[];

  eventTemplateStats?: any;
  eventSponsors?: EventSponsor[];
  eventTestimonials?: EventTestimonial[];
  eventWishlistItems?: EventWishlistItem[];
  eventAssets?: EventAsset[];

  legalInfoId?: number;
  legalInfo?: LegalInfo;
  eventNews?: EventNewsItem[];

  languageCodes: string[];

  translations: EventTemplateTranslation[];

  languageCode: string;

  eventTemplateTranslationSourceId?: number;
  eventTemplateTranslationSource?: EventTemplate;

  eventTemplateTranslations?: EventTemplate[];
  layout?: EventTemplateLayout;
  eventPictureInfos?: EventPictureInfo[];

  constructor(json: ClassData<EventTemplate>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.type = json.type;

    if (json.originalId !== undefined) {
      this.originalId = +json.originalId;
    }

    this.creatorId = +json.creatorId;
    if (json.creator) {
      this.creator = new User(json.creator);
    }

    this.uri = json.uri;

    this.name = json.name;

    this.description = json.description;
    this.eventName = json.eventName;
    this.eventCta = json.eventCta;

    this.access = json.access;
    this.relevance = json.relevance;

    this.shownOnSite = !!json.shownOnSite;

    if (json.eventCategories) {
      this.eventCategories = this.mapEventCategories(json.eventCategories);
    }
    if (json.eventSubcategories) {
      this.eventSubcategories = this.mapEventCategories(
        json.eventSubcategories,
      );
    }
    if (json.eventSponsors) {
      this.eventSponsors = this.mapEventSponsors(json.eventSponsors);
    }
    if (json.eventTestimonials) {
      this.eventTestimonials = this.mapEventTestimonials(
        json.eventTestimonials,
      );
    }

    if (json.eventPictureInfos) {
      this.eventPictureInfos = this.mapEventPictureInfos(
        json.eventPictureInfos,
      );
    }

    if (json.eventAssets) {
      this.eventAssets = this.mapEventAssets(json.eventAssets);
    }

    if (json.startDate) {
      this.startDate = new Date(json.startDate);
    }
    if (json.endDate) {
      this.endDate = new Date(json.endDate);
    }
    this.timeZone = json.timeZone;

    if (json.promotionStartDate) {
      this.promotionStartDate = new Date(json.promotionStartDate);
    }
    if (json.promotionEndDate) {
      this.promotionEndDate = new Date(json.promotionEndDate);
    }
    this.promotionTimeZone = json.promotionTimeZone;

    if (json.targetGroups) {
      this.targetGroups = json.targetGroups;
    }

    this.visualId = +json.visualId;
    if (json.visual) {
      this.visual = new EventTemplateVisual(json.visual);
    }

    this.fontId = +json.fontId;
    if (json.font) {
      this.font = new EventTemplateFont(json.font);
    }

    this.widgets = json.widgets;
    this.widgetsFull = json.widgetsFull;
    this.kits = json.kits;

    if (json.partnerId !== undefined) {
      this.partnerId = +json.partnerId;
    }
    if (json.partner) {
      this.partner = new User(json.partner);
    }

    if (json.layout) {
      this.layout = json.layout;
    }

    if (json.affiliateProducts) {
      this.affiliateProducts = this.mapEventTemplateAffiliateProducts(
        json.affiliateProducts,
      );
    }

    this.eventAddressId = +json.eventAddressId;
    if (json.eventAddress) {
      this.eventAddress = new Address(json.eventAddress);
    }

    this.standardInvitationText = json.standardInvitationText;

    this.invitationCardId = +json.invitationCardId;
    if (json.invitationCard) {
      this.invitationCard = new EventTemplateInvitationCard(
        json.invitationCard,
      );
    }

    this.seoId = +json.seoId;
    if (json.seo) {
      this.seo = new EventTemplateSeo(json.seo);
    }
    if (json.attendeeQuestions) {
      this.attendeeQuestions = this.mapAttendeeQuestions(
        json.attendeeQuestions,
      );
    }

    if (json.rsvpSettings) {
      this.rsvpSettings = new EventRSVPSettings(json.rsvpSettings);
    }

    if (json.eventSections) {
      this.eventSections = this.mapEventSections(json.eventSections);
    }

    if (json.eventWishlistItems) {
      this.eventWishlistItems = this.mapEventWishlistItems(
        json.eventWishlistItems,
      );
    }

    if (json.eventTimetableId) {
      this.eventTimetableId = +json.eventTimetableId;
    }
    if (json.eventTimetable) {
      this.eventTimetable = new EventTimetable(json.eventTimetable);
    }

    if (json.eventWeddingPartyId) {
      this.eventWeddingPartyId = +json.eventWeddingPartyId;
    }
    if (json.eventWeddingParty) {
      this.eventWeddingParty = new EventWeddingParty(json.eventWeddingParty);
    }

    if (json.eventFaqs) {
      this.eventFaqs = this.mapEventFaqs(json.eventFaqs);
    }

    if (json.legalInfoId !== undefined) {
      this.legalInfoId = +json.legalInfoId;
    }
    if (json.legalInfo) {
      this.legalInfo = new LegalInfo(json.legalInfo);
    }

    if (json.eventNews) {
      this.eventNews = json.eventNews.map(
        (newsItem) => new EventNewsItem(newsItem),
      );
    }
    if (json.keywords) {
      this.keywords = json.keywords;
    }

    this.eventTemplateStats = json.eventTemplateStats;

    this.languageCodes = json.languageCodes;

    this.translations = json.translations;

    this.languageCode = json.languageCode;

    if (json.eventTemplateTranslationSourceId !== undefined) {
      this.eventTemplateTranslationSourceId =
        +json.eventTemplateTranslationSourceId;
    }

    if (json.eventTemplateTranslationSource) {
      this.eventTemplateTranslationSource = json.eventTemplateTranslationSource;
    }

    if (json.eventTemplateTranslations) {
      this.eventTemplateTranslations = json.eventTemplateTranslations;
    }
  }

  getAfterAffiliateProducts(
    type: EventAffiliateProductType,
  ): AffiliateProduct[] {
    if (!this.affiliateProducts) {
      return [];
    }
    const afterCreationAffiliateProducts = this.affiliateProducts.filter(
      (product) => !!product.affiliateProduct && product.type === type,
    );

    return afterCreationAffiliateProducts.map(
      (product) => product.affiliateProduct!,
    );
  }

  getLink(selectedLanguage?: string): string {
    if (!selectedLanguage || selectedLanguage === this.languageCode) {
      return `${env.website}/design-templates/${this.uri}`;
    }

    const translation = this.getTranslation();

    if (translation && translation.languageCode === selectedLanguage) {
      return `${env.website}/design-templates/${translation.uri}`;
    }

    return `${env.website}/design-templates/${this.uri}`;
  }

  getEventCreationRequest(): EventCreationRequest {
    return {
      eventTemplateId: this.id,
      name: this.eventName,
    };
  }

  getEventSectionsOrdered(): EventSection[] | undefined {
    return this.eventSections?.sort((a, b) => a.order - b.order);
  }

  getEventSectionByType(
    type: EventSectionType,
    imagePosition?: ImagePosition,
  ): EventSection | undefined {
    if (imagePosition) {
      return this.eventSections?.find(
        (section) =>
          section.type === type && section.imagePosition === imagePosition,
      );
    }
    return this.eventSections?.find(
      (section) => section.type === type && !section.imagePosition,
    );
  }

  getNameWFallback(): string {
    if ((!this.name || this.name === '') && this.translations) {
      for (const translation of this.translations) {
        if (translation.name && translation.name !== '') {
          return translation.name;
        }
      }
    }

    return this.name;
  }

  getWidgets() {
    return (
      this.widgets?.filter((widget) => widget !== EventWidget.SELL_TICKETS) ||
      []
    );
  }

  getRsvpAttendeeQuestions() {
    return this.attendeeQuestions
      ?.filter((x) => x.isRsvpDataField)
      .sort((a, b) => a.order - b.order);
  }
  isPublic(): boolean {
    return this.access === EventTemplateAccess.PUBLIC;
  }

  getTranslation(): EventTemplate | undefined {
    if (
      !this.eventTemplateTranslations ||
      this.eventTemplateTranslations.length === 0
    ) {
      if (
        this.eventTemplateTranslationSource &&
        this.eventTemplateTranslationSource.id > 0
      ) {
        return this.eventTemplateTranslationSource;
      }
      return undefined;
    }
    return this.eventTemplateTranslations[0];
  }

  getLanguages(): string[] {
    const translation = this.getTranslation();

    if (!translation) {
      return [this.languageCode];
    }

    return [this.languageCode, translation.languageCode];
  }

  private mapEventCategories(categories: EventCategory[]): EventCategory[] {
    return categories.map((it) => new EventCategory(it));
  }

  private mapEventTemplateAffiliateProducts(
    products: EventTemplateAffiliateProduct[],
  ): EventTemplateAffiliateProduct[] {
    return products.map((it) => new EventTemplateAffiliateProduct(it));
  }

  private mapEventSections(sections: EventSection[]): EventSection[] {
    return sections.map((it) => new EventSection(it));
  }

  private mapEventWishlistItems(
    items: EventWishlistItem[],
  ): EventWishlistItem[] {
    return items.map((it) => new EventWishlistItem(it));
  }

  getAttendeeQuestionsSorted(): EventAttendeeQuestion[] | undefined {
    return this.attendeeQuestions?.sort((a, b) => a.order - b.order);
  }

  getAttendeeQuestionsSortedForTable(): EventAttendeeQuestion[] | undefined {
    return this.attendeeQuestions?.sort((a, b) => b.order - a.order);
  }

  getSanitizedDescription(): string | undefined {
    return this.description.replace(/<\/?p>/g, '') || undefined;
  }

  private mapAttendeeQuestions(
    questions: EventAttendeeQuestion[],
  ): EventAttendeeQuestion[] {
    return questions.map((question) => new EventAttendeeQuestion(question));
  }

  private mapEventSponsors(eventSponsors: EventSponsor[]): EventSponsor[] {
    return eventSponsors.map((sponsor) => new EventSponsor(sponsor));
  }

  private mapEventTestimonials(
    eventTestimonials: EventTestimonial[],
  ): EventTestimonial[] {
    return eventTestimonials.map(
      (testimonial) => new EventTestimonial(testimonial),
    );
  }

  private mapEventPictureInfos(
    mapEventPictureInfos: EventPictureInfo[],
  ): EventPictureInfo[] {
    return mapEventPictureInfos.map((info) => new EventPictureInfo(info));
  }

  private mapEventAssets(eventAssets: EventAsset[]): EventAsset[] {
    return eventAssets.map((asset) => new EventAsset(asset));
  }

  private mapEventFaqs(faqs: EventFaq[]): EventFaq[] {
    return faqs.map((it) => new EventFaq(it));
  }
}

export function mapCreationRequestToEventTemplate(
  req: EventTemplateCreationRequest,
): EventTemplate {
  let visual = new EventTemplateVisual({
    id: 0,
    thumbnailUrl: '',
    backgroundColor: '',
    footerColor: '',
    marketplaceVisuals: [],
  });
  if (req.visual) {
    visual.thumbnailUrl = req.visual.thumbnailAsset
      ? createDataUrl(req.visual.thumbnailAsset)
      : '';
    visual.logoUrl = req.visual.logoAsset
      ? createDataUrl(req.visual.logoAsset)
      : '';
    visual.keyVisual1Id = req.visual.keyVisual1Id;
    visual.keyVisual1 = req.visual.keyVisual1;
    visual.keyVisual2Url = req.visual.keyVisual2Asset
      ? createDataUrl(req.visual.keyVisual2Asset)
      : '';
    visual.patternUrl = req.visual.patternAsset
      ? createDataUrl(req.visual.patternAsset)
      : '';
    visual.backgroundId = req.visual.backgroundId;
    visual.background = req.visual.background;
    visual.backgroundColor = req.visual.backgroundColor;
    visual.footerColor = req.visual.footerColor;

    visual.effectId = req.visual.effectId;
    visual.effect = req.visual.effect;

    visual.marketplaceVisuals = req.visual.marketplaceVisuals
      ? req.visual.marketplaceVisuals.map(
          (asset) =>
            new EventTemplateMarketplaceVisual({
              url: createDataUrl(asset),
              title: asset.title || '',
              alt: asset.alt || '',
            }),
        )
      : [];
  }

  let font = new EventTemplateFont({
    id: 0,
    primaryLogoFont: '',
    primaryLogoFontColor: '',
    bodyFont: '',
    bodyFontColor: '',
  });
  if (req.font) {
    font.primaryLogoFont = req.font.primaryLogoFont;
    font.primaryLogoFontColor = req.font.primaryLogoFontColor;
    font.primaryFontWeight = req.font.primaryFontWeight;
    font.bodyFont = req.font.bodyFont;
    font.bodyFontColor = req.font.bodyFontColor;
    font.bodyFontWeight = req.font.bodyFontWeight;
    font.signalFontColor = req.font.signalFontColor;

    font.sectionHeadlineColor = req.font.sectionHeadlineColor;
    font.sectionBodyColor = req.font.sectionBodyColor;
    font.sectionBackgroundColor = req.font.sectionBackgroundColor;

    font.sectionAccentHeadlineColor = req.font.sectionAccentHeadlineColor;
    font.sectionAccentBodyColor = req.font.sectionAccentBodyColor;
    font.sectionAccentBackgroundColor = req.font.sectionAccentBackgroundColor;
  }

  let invitationCard = new EventTemplateInvitationCard({
    id: 0,
    title: '',
    invitationText: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
  });
  if (req.invitationCard) {
    invitationCard.title = req.invitationCard.title;
    invitationCard.invitationText = req.invitationCard.invitationText;
    invitationCard.contactName = req.invitationCard.contactName;
    invitationCard.contactEmail = req.invitationCard.contactEmail;
    invitationCard.contactPhone = req.invitationCard.contactPhone;
  }

  let seo = new EventTemplateSeo({
    id: 0,
    title: '',
    description: '',
    sharingPictureUrl: '',
  });
  if (req.seo) {
    seo.sharingPictureUrl = req.seo.sharingPictureAsset
      ? createDataUrl(req.seo.sharingPictureAsset)
      : '';
    seo.title = req.seo.title;
    seo.description = req.seo.description;
    seo.pageDetailSummary = req.seo.pageDetailSummary;
    seo.pageDetail = req.seo.pageDetail;
  }

  return new EventTemplate({
    id: 0,

    type: EventType.PARTYPAGE,

    creatorId: 0,

    uri: req.uri,

    name: req.name,

    description: req.description,
    eventName: req.eventName,
    eventCta: req.eventCta,

    access: req.access,
    relevance: req.relevance,

    shownOnSite: req.shownOnSite,

    startDate: req.startDate,
    endDate: req.endDate,
    timeZone: req.timeZone,

    promotionStartDate: req.promotionStartDate,
    promotionEndDate: req.promotionEndDate,
    promotionTimeZone: req.promotionTimeZone,

    targetGroups: req.targetGroupIds,

    visualId: 0,
    visual: visual,

    fontId: 0,
    font: font,

    widgets: req.widgets,
    kits: req.kits,

    partnerId: req.partnerId,

    eventAddressId: 0,
    eventAddress: req.eventAddress ? new Address(req.eventAddress) : undefined,

    standardInvitationText: req.standardInvitationText,

    invitationCardId: 0,
    invitationCard: invitationCard,

    seoId: 0,
    seo: seo,

    eventTemplateStats: {},

    languageCodes: [],

    translations: [],

    languageCode: req.languageCode,
  });
}
