export enum LegalInfoLinkType {
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  DATA_PRIVACY = 'DATA_PRIVACY',
  IMPRINT = 'IMPRINT',
  CONTACT = 'CONTACT',
  NEWS = 'NEWS',
  REGIONAL_NEWS = 'REGIONAL_NEWS',
  BLOG = 'BLOG',
  NEWSLETTER = 'NEWSLETTER',
  CUSTOM = 'CUSTOM',
}

export namespace LegalInfoLinkType {
  export function getDisplayName(type?: LegalInfoLinkType): string {
    switch (type) {
      case LegalInfoLinkType.TERMS_AND_CONDITIONS:
        return 'APP.LEGAL_INFO.LINK_DISPLAY.TERMS_AND_CONDITIONS';
      case LegalInfoLinkType.DATA_PRIVACY:
        return 'APP.LEGAL_INFO.LINK_DISPLAY.DATA_PRIVACY';
      case LegalInfoLinkType.IMPRINT:
        return 'APP.LEGAL_INFO.LINK_DISPLAY.IMPRINT';
      case LegalInfoLinkType.CONTACT:
        return 'APP.LEGAL_INFO.LINK_DISPLAY.CONTACT';
      case LegalInfoLinkType.NEWS:
        return 'APP.LEGAL_INFO.LINK_DISPLAY.NEWS';
      case LegalInfoLinkType.REGIONAL_NEWS:
        return 'APP.LEGAL_INFO.LINK_DISPLAY.REGIONAL_NEWS';
      case LegalInfoLinkType.BLOG:
        return 'APP.LEGAL_INFO.LINK_DISPLAY.BLOG';
      case LegalInfoLinkType.NEWSLETTER:
        return 'APP.LEGAL_INFO.LINK_DISPLAY.NEWSLETTER';
      case LegalInfoLinkType.CUSTOM:
        return 'APP.LEGAL_INFO.LINK_DISPLAY.CUSTOM';
      default:
        return '';
    }
  }

  export function getLabel(type?: LegalInfoLinkType): string {
    switch (type) {
      case LegalInfoLinkType.TERMS_AND_CONDITIONS:
        return 'APP.LEGAL_INFO.LINK_LABEL.TERMS_AND_CONDITIONS';
      case LegalInfoLinkType.DATA_PRIVACY:
        return 'APP.LEGAL_INFO.LINK_LABEL.DATA_PRIVACY';
      case LegalInfoLinkType.IMPRINT:
        return 'APP.LEGAL_INFO.LINK_LABEL.IMPRINT';
      case LegalInfoLinkType.CONTACT:
        return 'APP.LEGAL_INFO.LINK_LABEL.CONTACT';
      case LegalInfoLinkType.NEWS:
        return 'APP.LEGAL_INFO.LINK_LABEL.NEWS';
      case LegalInfoLinkType.REGIONAL_NEWS:
        return 'APP.LEGAL_INFO.LINK_LABEL.REGIONAL_NEWS';
      case LegalInfoLinkType.BLOG:
        return 'APP.LEGAL_INFO.LINK_LABEL.BLOG';
      case LegalInfoLinkType.NEWSLETTER:
        return 'APP.LEGAL_INFO.LINK_LABEL.NEWSLETTER';
      case LegalInfoLinkType.CUSTOM:
        return 'APP.LEGAL_INFO.LINK_LABEL.CUSTOM';
      default:
        return '';
    }
  }
}
