import { ClassData } from '@models/class-data';

export class EventAsset {
  id: number;
  createdAt?: Date;

  eventId: number;
  eventCommentId?: number;

  userId: number;

  name: string;
  mimeType: string;
  url: string;

  isApproved: boolean;
  isGalleryAsset: boolean;
  order: number;

  constructor(json: ClassData<EventAsset>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.eventId = +json.eventId;
    if (json.eventCommentId !== undefined) {
      this.eventCommentId = +json.eventCommentId;
    }

    this.userId = +json.userId;

    this.name = json.name;
    this.mimeType = json.mimeType;
    this.url = json.url;

    this.isApproved = json.isApproved;
    this.isGalleryAsset = json.isGalleryAsset;
    this.order = json.order;
  }
}
