import { ClassData } from '@models/class-data';
import { LegalInfoLink } from './legal-info-link.model';
import { LegalInfoLinkType } from './enums/legal-info-link-type.enum';

export class LegalInfo {
  id: number;

  companyName: string;

  address: string;
  postalCode: string;
  locality: string;
  country?: string;

  phone: string;
  email: string;
  website: string;

  links: LegalInfoLink[];

  constructor(json: ClassData<LegalInfo>) {
    this.id = json.id;

    this.companyName = json.companyName;

    this.address = json.address;
    this.postalCode = json.postalCode;
    this.locality = json.locality;
    this.country = json.country;

    this.phone = json.phone;
    this.email = json.email;
    this.website = json.website;

    this.links = json.links
      .map((link) => new LegalInfoLink(link))
      .sort((a, b) => a.order - b.order);
  }

  getLinkByType(type: LegalInfoLinkType): LegalInfoLink | undefined {
    return this.links.find((link) => link.type === type);
  }
}
