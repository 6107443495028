import { ClassData } from '@models/class-data';
import { EventCategory } from '@models/event-categories/event-category.model';

export class EventTemplateFrame {
  id: number;
  createdAt?: Date;
  name: string;
  thumbnailUrl: string;
  headerUrl: string;
  headerMobileUrl: string;
  footerUrl: string;
  footerMobileUrl: string;
  categories?: EventCategory[];
  keywordIds?: number[];

  constructor(json: ClassData<EventTemplateFrame>) {
    this.id = +json.id;

    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.name = json.name;
    this.thumbnailUrl = json.thumbnailUrl;
    this.headerUrl = json.headerUrl;
    this.headerMobileUrl = json.headerMobileUrl;
    this.footerUrl = json.footerUrl;
    this.footerMobileUrl = json.footerMobileUrl;

    if (json.categories) {
      this.categories = this.mapEventCategories(json.categories);
    }

    if (json.keywordIds) {
      this.keywordIds = json.keywordIds;
    }
  }

  private mapEventCategories(categories: EventCategory[]): EventCategory[] {
    return categories.map((it) => new EventCategory(it));
  }

  getEventCategoriesIds(): number[] | undefined {
    if (!this.categories) {
      return undefined;
    }

    const mainCategories = this.categories
      .filter((category) => !category.parentCategoryId)
      .map((category) => category.id);

    return [...new Set(mainCategories)];
  }

  getEventSubcategoriesIds(): number[] | undefined {
    if (!this.categories) {
      return undefined;
    }

    return this.categories
      .filter(
        (category) =>
          category.parentCategoryId && category.parentCategoryId > 0,
      )
      .map((category) => category.id);
  }

  getEventSubcategoriesNames(): string {
    if (!this.categories) {
      return '';
    }

    const subCategories = this.categories.filter(
      (category) => category.parentCategoryId && category.parentCategoryId > 0,
    );

    if (subCategories.length === 0) {
      return '';
    }

    return subCategories.map((category) => category.name).join(', ');
  }

  hasHeaderFrame(): boolean {
    return this.headerUrl !== '' || this.headerMobileUrl !== '';
  }

  hasFooterFrame(): boolean {
    return this.footerUrl !== '' || this.footerMobileUrl !== '';
  }
}
