export enum EventType {
  PARTYPAGE = 'PARTYPAGE',
  WEDDINGPAGE = 'WEDDINGPAGE',
  INVITATION = 'INVITATION',
  SPORTPAGE = 'SPORTPAGE',
  LANDINGPAGE = 'LANDINGPAGE',
  ADVANCED_ONE_COLUMN = 'ADVANCED_ONE_COLUMN',
  GENERIC_LANDINGPAGE = 'GENERIC_LANDINGPAGE',
  GENERIC_EVENT = 'GENERIC_EVENT',
}
