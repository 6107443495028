import { ClassData } from '@models/class-data';
import { ButtonVariant } from '@models/embedded-section/button-variant.enum';
import { EventCTAButtonActionType } from '@models/events/event-cta-button-action-type.enum';
import { EventCTAButtonType } from '@models/events/event-cta-button-type.enum';
export class EventCtaButton {
  id: number;
  fontSize?: string;
  color?: string;
  backgroundColor?: string;
  hoverColor?: string;
  hoverBackgroundColor?: string;
  width?: string;
  height?: string;
  border?: string;
  borderRadius?: string;
  variant?: ButtonVariant;
  shadow?: string;
  shadowOffset?: string;
  shadowBlur?: string;
  shadowColor?: string;
  text?: string;
  link?: string;
  order: number;
  type?: EventCTAButtonType;
  action?: EventCTAButtonActionType;
  helperText?: string;

  constructor(json: ClassData<EventCtaButton>) {
    this.id = +json.id;
    this.backgroundColor = json.backgroundColor;
    this.hoverColor = json.hoverColor;
    this.hoverBackgroundColor = json.hoverBackgroundColor;
    this.width = json.width;
    this.height = json.height;
    this.border = json.border;
    this.borderRadius = json.borderRadius;
    this.variant = json.variant;
    this.shadow = json.shadow;
    this.shadowOffset = json.shadowOffset;
    this.shadowBlur = json.shadowBlur;
    this.shadowColor = json.shadowColor;
    this.text = json.text;
    this.link = json.link;
    this.order = json.order;
    this.type = json.type;
    this.action = json.action;
    this.color = json.color;
    this.helperText = json.helperText;
  }
}
