import { ClassData } from '@models/class-data';

export class EventPictureInfo {
  id: number;
  eventTemplateId: number;
  name?: string;
  description?: string;
  url?: string;

  constructor(json: ClassData<EventPictureInfo>) {
    this.id = +json.id;
    this.eventTemplateId = +json.eventTemplateId;
    this.name = json.name;
    this.description = json.description;
    this.url = json.url;
  }
}
