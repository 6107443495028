import { ClassData } from '@models/class-data';
import { LegalInfoLinkType } from './enums/legal-info-link-type.enum';

export class LegalInfoLink {
  id: number;

  legalInfoId: number;

  type: LegalInfoLinkType;

  link: string;
  customLabel: string;

  order: number;

  constructor(json: ClassData<LegalInfoLink>) {
    this.id = +json.id;

    this.legalInfoId = +json.legalInfoId;

    this.type = json.type;

    this.link = json.link;
    this.customLabel = json.customLabel;

    this.order = +json.order;
  }

  getControlName(): string {
    switch (this.type) {
      case LegalInfoLinkType.TERMS_AND_CONDITIONS:
        return 'termsAndConditionsLink';
      case LegalInfoLinkType.DATA_PRIVACY:
        return 'dataPrivacyLink';
      case LegalInfoLinkType.IMPRINT:
        return 'imprintLink';
      case LegalInfoLinkType.CONTACT:
        return 'contactLink';
      case LegalInfoLinkType.NEWS:
        return 'newsLink';
      case LegalInfoLinkType.REGIONAL_NEWS:
        return 'regionalNewsLink';
      case LegalInfoLinkType.BLOG:
        return 'blogLink';
      case LegalInfoLinkType.NEWSLETTER:
        return 'newsletterLink';
      default:
        return '';
    }
  }
}
