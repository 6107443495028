import { ClassData } from '@models/class-data';

export class EventWishlistItem {
  id: number;
  createdAt?: string;
  eventTemplateId?: number;
  pictureUrl?: string;
  linkUrl?: string;
  order: number;

  constructor(json: ClassData<EventWishlistItem>) {
    this.id = +json.id;
    this.createdAt = json.createdAt;
    this.eventTemplateId = json.eventTemplateId;
    this.pictureUrl = json.pictureUrl;
    this.linkUrl = json.linkUrl;
    this.order = json.order;
  }
}
