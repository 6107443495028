import { ClassData } from '@models/class-data';
import { EventCtaButton } from '@models/events/event-cta-button.model';
import { EventSectionBackgroundSize } from '@models/events/event-section-background-size.enum';
import { ImagePosition } from '@models/events/image-position.enum';
import { TextPosition } from '@models/shared/text-position.enum';
import { EventSectionType } from './event-section-type.enum';
import { EventSectionVariantType } from '@models/events/event-section-variant-type.enum';
import { EventSectionLayoutType } from '@models/events/event-section-layout-type.enum';
import { EventSectionDirectionType } from '@models/events/event-section-direction-type.enum';
import { EventSectionWidthType } from '@models/events/event-section-width-type.enum';

export class EventSection {
  id: number;

  eventId?: number;
  eventTemplateId?: number;

  parentEventSectionId?: number;

  type: EventSectionType;

  mainIcon?: string;
  mainTitle?: string;

  headline?: string;
  title: string;
  description: string;
  descriptionPosition?: TextPosition;

  headlineColor?: string;
  bodyColor?: string;

  backgroundColor?: string;
  backgroundImageUrl?: string;
  backgroundSize?: EventSectionBackgroundSize;
  assetUrl?: string;

  order: number;
  hidden?: boolean;

  imageUrl?: string;
  imagePosition?: ImagePosition;
  variant?: EventSectionVariantType;
  layout?: EventSectionLayoutType;
  direction?: EventSectionDirectionType;
  widthType?: EventSectionWidthType;
  width?: string;
  subSections?: EventSection[];
  eventCtaButtons?: EventCtaButton[];

  constructor(json: ClassData<EventSection>) {
    this.id = +json.id;

    if (json.eventId !== undefined) {
      this.eventId = +json.eventId;
    }
    if (json.eventTemplateId !== undefined) {
      this.eventTemplateId = +json.eventTemplateId;
    }

    if (json.parentEventSectionId !== undefined) {
      this.parentEventSectionId = +json.parentEventSectionId;
    }

    this.type = json.type;

    this.mainIcon = json.mainIcon;
    this.mainTitle = json.mainTitle;

    this.headline = json.headline;
    this.title = json.title;
    this.description = json.description;
    this.descriptionPosition = json.descriptionPosition;

    this.headlineColor = json.headlineColor;
    this.bodyColor = json.bodyColor;

    this.backgroundColor = json.backgroundColor;
    this.backgroundImageUrl = json.backgroundImageUrl;
    this.backgroundSize = json.backgroundSize;
    this.assetUrl = json.assetUrl;

    this.order = json.order;
    this.hidden = json.hidden;

    this.imageUrl = json.imageUrl;
    this.imagePosition = json.imagePosition;

    this.variant = json.variant;
    this.layout = json.layout;
    this.direction = json.direction;
    this.widthType = json.widthType;
    this.width = json.width;

    if (json.subSections) {
      this.subSections = this.mapSubSections(json.subSections);
    }

    if (json.eventCtaButtons) {
      this.eventCtaButtons = this.mapEventCtaButtons(json.eventCtaButtons);
    }
  }
  getSubSectionsOrdered(): EventSection[] | undefined {
    return this.subSections?.sort((a, b) => a.order - b.order);
  }

  getSubSectionByType(type: EventSectionType) {
    return this.getSubSectionsOrdered()?.find((x) => x.type === type);
  }

  isSectionBgVideo(): boolean {
    return !!this.backgroundImageUrl?.includes('.mp4');
  }

  isSectionImageVideo(): boolean {
    return !!this.imageUrl?.includes('.mp4');
  }

  private mapSubSections(subSections: EventSection[]): EventSection[] {
    return subSections.map((it) => new EventSection(it));
  }

  private mapEventCtaButtons(
    eventCtaButtons: EventCtaButton[],
  ): EventCtaButton[] {
    return eventCtaButtons.map((it) => new EventCtaButton(it));
  }
}
